.tremaze-button {
  padding: 0 1rem;
  height: var(--button-h);
  transition: background-color 125ms linear;
  border-radius: var(--button-r);
  outline: none;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  overflow: hidden;
  font-size: 14pt;
  text-decoration: none;
  margin: 0;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: calc(1.75em / 16) solid transparent;
  line-height: 1em;
  background: transparent;

  // COLORS
  &--Accent {
    --bg: var(--accent);
    --fg: var(--accent-fg);
    --d: var(--accent-d);
    --sl: var(--accent-sl);
  }

  &--Primary {
    --bg: var(--primary);
    --fg: var(--primary-fg);
    --d: var(--primary-d);
    --sl: var(--primary-sl);
  }

  &--Warn {
    --bg: var(--warn);
    --fg: var(--warn-fg);
    --d: var(--warn-d);
    --sl: var(--warn-sl);
  }

  //MODES

  &--Elevated,
  &--Icon {
    background: var(--bg);
    color: var(--fg);

    &:not(.disabled) {
      box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
    }
  }

  &--Outlined {
    border-color: var(--border);
    color: var(--bg);

    &:focus,
    &:hover {
      background: var(--sl);
    }
  }

  &--Flat {
    color: var(--bg);
    background: transparent;

    &:focus,
    &:hover {
      background: var(--sl);
    }
  }

  &--Icon {
    font-size: 0.9rem;
    border: none;
    border-radius: 50%;
    padding: 0;
    height: 2.5em;
    width: 2.5em;
    max-width: 2.5em;
    max-height: 2.5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    > .lnr {
      line-height: 1em;
      height: 1em;
      width: 1em;
      text-align: center;
      font-weight: 900;
    }

    &.tremaze-suffix {
      background: transparent;
    }
  }

  &--Icon,
  &--Elevated {
    &:hover,
    &:focus {
      background-color: var(--d);
    }

    &:focus-visible {
      outline-offset: 2px;
      outline: var(--d) dashed 2px;
    }
  }

  &:not(&--Icon) {
    > .lnr {
      margin-right: 0.75em;
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
    background: var(--disabled);
  }

  &.loading {
    position: relative;

    > tremaze-spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--disabled);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
