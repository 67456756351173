.tremaze-input {
  border-radius: 0.5rem;
  padding: 0.75rem;
  outline: none;
  margin: 0;
  border: calc(1rem / 16) solid gray;
  font-family: inherit;
  transition: border 100ms, margin 100ms;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border-color: var(--primary);
  }

  &:focus,
  &.ng-invalid.ng-touched {
    border-width: calc(1rem / 8);
    margin: 0;
  }

  &.ng-invalid.ng-touched {
    border-color: var(--warn);
  }
}

input.tremaze-input {
  box-sizing: border-box;
  height: calc(46rem / 16);
}
