@use '@angular/material' as mat;
@import url('https://assets.tremaze.de/fonts/Sarabun/fontface.css');
@import 'libs/shared/ui/src/lib/assets/styles/helpers/helpers.helpers';
@import 'libs/shared/ui/inputs/input/src/lib/input';
@import 'libs/shared/ui/button/src/lib/button';

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import 'libs/shared/ui/src/lib/assets/styles/elements/elements.scrollbar';
@import 'libs/shared/ui/src/lib/assets/styles/elements/elements.table';
@import 'libs/shared/ui/src/lib/assets/styles/elements/elements.forms';

@import 'node_modules/hamburgers/_sass/hamburgers/hamburgers';

@media only screen and (max-width: 800px) {
  .ng-animating {
    .tremaze-button-bar--Mobile-Sticky {
      display: none !important;
    }
  }
}

.index-router-container {
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
  flex: 1;

  router-outlet ~ .ng-animating {
    grid-row-start: 1;
    grid-column-start: 1;
    display: block !important;
    width: 100%;

    > * {
      min-width: 100%;
      width: 100%;
    }
  }
}

html {
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
  min-height: 100%;
  display: flex;
  scroll-behavior: smooth;

  --page-horizontal-padding: 1.5rem;
  --foreground: black;
  --disabled: #bfbfbf;
  --border: rgba(0, 0, 0, 0.12);
  --button-h: calc(40rem / 16);
  --button-r: calc(var(--button-h) / 2);
  --card-r: 1rem;
  --background: white;
  --bg-emerge: #f2eeea;

  --primary: #1e5674;
  --primary-d: #19465f;
  --primary-sl: rgba(30, 86, 116, 0.1);
  --primary-fg: white;

  --accent: #e37514;
  --accent-d: #db7a25;
  --accent-sl: rgba(239, 133, 41, 0.1);
  --accent-fg: white;

  --warn: red;
  --warn-d: #e50101;
  --warn-sl: rgba(255, 0, 0, 0.1);
  --warn-fg: white;

  background: var(--background);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 1.2em;
  }

  h1 {
    font-size: calc(40rem / 16);
  }

  h2 {
    font-size: 2rem;
  }

  h4 {
    font-size: calc(18rem / 16);
  }

  .innerHTML {
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
  }

  .innerHTML > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

}

.color-primary {
  color: var(--primary);
}

.color-accent {
  color: var(--accent);
}

a {
  color: var(--accent);

  &:focus-visible {
    outline-offset: 1px;
    outline: var(--accent-d) dashed 2px;
  }
}

p {
  margin: 0;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
  flex: 1;
}

p {
  line-height: calc(26em / 16);
}

.mat-mdc-menu-content {
  .lnr {
    font-size: 1.2rem;
    margin-right: 0.25rem;
    transform: translateY(0.2rem);
    display: inline-block;
  }
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  min-height: 100%;
  overflow: hidden;

  &--Inline {
    min-height: 0;
  }

  &__Foot {
    width: 100%;
  }

  &__Body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;

    router-outlet:first-of-type + * {
      display: contents;
    }
  }

  router-outlet {
    display: none;
  }

  &__Section {
    max-width: 100%;
    $w: 1000px;
    width: $w;
    $p: var(--page-horizontal-padding);
    @media only screen and (max-width: 1070px) {
      padding: 0 $p;
    }
    margin-bottom: 2rem;
    box-sizing: border-box;

    &--1200 {
      width: 1200px;
    }

    &--wide {
      width: 1920px;
      padding: 0;
    }

    &--NP {
      padding: 0;
    }
  }
}

.visibility-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

tremaze-event-item {
  color: black;
}

@for $i from 3 through 12 {
  @media only screen and (max-width: #{$i * 100}px) {
    .page__Section--NP-#{$i * 100} {
      padding: 0;
    }
  }
}


mat-bottom-sheet-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.mat-checkbox-layout {
  white-space: normal !important;
}


.mat-mdc-dialog-actions {
  padding: 16px 24px;
}
